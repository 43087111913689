
.vdt::-webkit-calendar-picker-indicator {
    opacity: 1;
    display: block;
    background-image: url('img/calendar.png');
    
        background-color: #35257d;
    color: white!important;
    padding: 10px;
    margin-right: -12px;
  
    border-radius: 0px;
}

.btn_side
{
    background-color: rgba(53, 119, 241, 0.1);
    color: #3577f1;
    padding: 5px;
    border: none;
    font-size: 12px;
    float: right;
    margin-right: 5px;
    border-radius: 2px;
    min-width: 40px;
    margin-top: -26px;
 
}

.btn_side:hover
{background-color: #3577f1;
    color: white;

}


.pad9
{
    padding: 9px!important;
}

.vin_db_btn:hover
{
    background-color:#214569;
    color: white;
}
.vin_db_btn
{
    display:inline-flex;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #d8e1ef;
    border: none;
    color: #214569;
    font-weight: 600;
}

.vin_card:hover
{
   
        -webkit-transition: all .4s!important;
    transition: all .6s!important;
    box-shadow: 0 6px 5px rgb(56 65 74 / 15%)!important;
    margin-top: -0.2rem!important;
    
}

.Vin_DB
{


    
    .vin_card
    {
        background-color: white;
        margin-bottom: 1.5rem;
    -webkit-box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
padding-bottom: 20px;
    border-radius: 0.25rem;
    min-height: 125px;
    .title
    {
        
        font-size: 14px;
        font-weight: 500;
        color: #878a99!important;

    }


    .green_per
    {
       
        border-radius: 0;
        color: #13bb37;
        padding: 0;
        font-size: 13px;
        font-weight: 400;

    }


    .red_per
    {
       
        border-radius: 0;
        color: #f06548;
        padding: 0;
        font-size: 13px;
        font-weight: 400;

    }

    .blue_per
    {
       
        border-radius: 0;
        color: #140235;
        padding: 0;
        font-size: 13px;
        font-weight: 400;

    }



   
    

    .vin_card_body
    {

        .l1
        {
            display: inline-flex;
            padding: 10px;
            width: 100%;
            line-height: 35px!important;

            .left {
                width: 70%;

            };

            .right{
                text-align: right;
                width: 30%;

            };
        }




        .l2
        {
            display: inline-flex;
            padding: 10px;
            padding-top: 0px;
            width: 100%;
           
            .vicon{

                background-color: rgba(10,179,156,.18)!important;
                color: #13bb37;
                padding: 10px;
                font-size: 25px;
                border-radius: 5px;
                position: absolute;
                right: 25px;
                bottom: 54px;
                border-radius: 0;
                font-weight: 400;
            }
            
            .vgreen
            {
                background-color: rgba(10,179,156,.18)!important;
                color: #13bb37;
            }

            .vblue
            {
                background-color:#d8e1ef!important;
                color: #140235;
            }


            .vskyblue
            {
                background-color: rgba(53, 119, 241, 0.1);
                color: #3577f1;

            }

            .vorange
            {
                background-color:  rgba(247,184,75,.18)!important;
                color: #f06548;
            }

        };

            .left {


                width: 70%;
                .Big_text
                {
                    font-size: 18px;
                    font-weight: 800;
                    color: #495057!important;
                    padding-bottom: 30px;
                }

                .smal_text
                {
                    font-size: 14px;
    
    color: #405189!important;
    position: absolute;
    bottom: 50px;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
                }

              

            .right{
                text-align: right;
                width: 30%;

            };
        }



    }
 
    }

}

.vin_DB_Tile
 {
        padding: 5px;
        padding-bottom: 10px;
        .vHead
        {
            color: #495057!important;
            font-size: 15px;
            font-weight: 700;
        }

        .vsub_Head
        {
            color: #878a99!important;
            font-size: 13px;
            font-weight: 500;
            
        }

        .Content_right
        {
          
            position: relative;
            float: right;
        }

        .vdate
        {
            background-color: #35257d;
            color: white;
            padding: 10px;
            position: absolute;
            right: 0;
        }

    }

.vmar-l5
{
    margin-left: 5px;
}

.vmar-R5
{
    margin-right: 5px;
}

.Vin_Box
{
    background-color: white;
    padding: 0px;
    border-radius: 0.25rem;
    .VB_Header
    {
        border-bottom: 1px solid #e9ebec;
     
        padding: 10px;
        .VB_H_left
        {
                .h_text
                {
                    font-size: 16px;
                    font-weight: 600;
                }
        }

        .VB_H_right
        {
            
        }
    }


    .VB_Body
    {

    }
}